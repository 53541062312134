import axios from 'axios';

const fetchPresignedUrl = async (bucketName, key) => {
	try {
		console.log('Bucket Name:', bucketName);
		console.log('Key:', key);
		console.log('Type of Key:', typeof key);

		const { data } = await axios.post('/api/upload/generate-presigned-url', {
			bucketName,
			key,
		});
		return data.url;
	} catch (error) {
		console.error('Error fetching pre-signed URL:', error);
		return null;
	}
};

const extractKeyFromUrl = (url) => {
	const urlObj = new URL(url);
	return decodeURIComponent(urlObj.pathname.substring(1)); // Remove the leading '/' and decode the key
};

const getVerificationDocsUrl = async (docs) => {
	console.log('Docs:', docs);
	const urls = await Promise.all(
		docs.map(async (doc) => {
			let key;
			if (typeof doc === 'string') {
				key = extractKeyFromUrl(doc);
			} else if (
				Array.isArray(doc) &&
				doc.length > 0 &&
				typeof doc[0] === 'string'
			) {
				key = extractKeyFromUrl(doc[0]);
			} else if (typeof doc === 'object' && doc.url) {
				key = extractKeyFromUrl(doc.url);
			} else {
				console.error('Invalid doc type:', typeof doc);
				return null;
			}
			const url = await fetchPresignedUrl('sowtrendy-dev-uat', key);
			return url;
		})
	);
	return urls;
};

export { fetchPresignedUrl, getVerificationDocsUrl };
