import React from 'react';
import PropTypes from 'prop-types';

const Rating = ({ value, text, color = '#f8e825' }) => {
	const renderStar = (index) => {
		return (
			<span key={index}>
				<i
					style={{ color }}
					className={
						value >= index
							? 'fas fa-star'
							: value >= index - 0.5
							? 'fas fa-star-half-alt'
							: 'far fa-star'
					}
				></i>
			</span>
		);
	};

	return (
		<div className='rating'>
			{[1, 2, 3, 4, 5].map((index) => renderStar(index))}
			{text && <span>{text}</span>}
		</div>
	);
};

/*Rating.propTypes = {
	value: PropTypes.number.isRequired,
	text: PropTypes.string,
	color: PropTypes.string,
};*/

export default Rating;
