import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { getUserDetails, updateUser } from '../actions/userActions';
import { USER_UPDATE_RESET } from '../constants/userConstants';
import axios from 'axios';

const UserEditScreen = () => {
	const { id: userId } = useParams();
	const navigate = useNavigate();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [role, setRole] = useState([]);
	const [roles, setRoles] = useState([]);
	const [active, setActive] = useState(false);

	const dispatch = useDispatch();

	const userDetails = useSelector((state) => state.userDetails);
	const { loading, error, user } = userDetails;

	const userUpdate = useSelector((state) => state.userUpdate);
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = userUpdate;
	useEffect(() => {
		const fetchRolesAndUser = async () => {
			const { data: rolesData } = await axios.get('/api/roles');
			setRoles(rolesData);

			if (!user.name || user._id !== userId) {
				dispatch(getUserDetails(userId));
			} else {
				setName(user.name);
				setEmail(user.email);
				const validRoles = user.role.filter((r) =>
					rolesData.some((role) => role._id === r)
				);
				setRole(validRoles);
				setActive(user.active);
			}
		};

		if (successUpdate) {
			dispatch({ type: USER_UPDATE_RESET });
			navigate('/admin/userlist');
		} else {
			fetchRolesAndUser();
		}
	}, [dispatch, navigate, userId, user, successUpdate]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(updateUser({ _id: userId, name, email, role, active }));

		const roleNames = role.map((roleId) => {
			const roleObj = roles.find((r) => r._id === roleId);
			return roleObj ? roleObj.name : '';
		});

		const isSellerRole =
			roleNames.includes('Seller-Individual') ||
			roleNames.includes('Seller-Company');
		if (active && isSellerRole) {
			try {
				axios.post('/api/users/seller-verified-email', { email });
			} catch (error) {
				console.error('Error sending verified email:', error);
			}
		}
	};

	return (
		<>
			<Link to='/admin/userlist' className='btn btn-light my-3'>
				Go Back
			</Link>
			<FormContainer>
				<h1>Edit User</h1>
				{loadingUpdate && <Loader />}
				{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
				{loading ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<Form onSubmit={submitHandler}>
						<Form.Group controlId='name'>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type='name'
								placeholder='Enter name'
								value={name}
								onChange={(e) => setName(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='email'>
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type='email'
								placeholder='Enter email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='role'>
							<Form.Label>Role</Form.Label>
							<Form.Control
								as='select'
								multiple
								value={role}
								onChange={(e) =>
									setRole(
										[...e.target.selectedOptions].map((option) => option.value)
									)
								}
							>
								{roles.map((role) => (
									<option key={role._id} value={role._id}>
										{role.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='active' className='mb-3'>
							<Form.Check
								type='checkbox'
								label='Active'
								checked={active}
								onChange={(e) => setActive(e.target.checked)}
							></Form.Check>
						</Form.Group>

						<Button type='submit' variant='primary'>
							Update
						</Button>
					</Form>
				)}
			</FormContainer>
		</>
	);
};

export default UserEditScreen;
