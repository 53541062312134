import React from 'react';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
	const location = useLocation();

	// Determine the class based on the current route
	const getPageClass = (pathname) => {
		if (process.env.NODE_ENV === 'development') {
			console.log(pathname);
		}

		if (/^\/admin\/product\/[^/]+\/edit$/.test(pathname)) {
			return 'seller-product-edit-page';
		}
		switch (pathname) {
			case '/seller/product/create':
				return 'seller-product-create-page';
			case '/register_seller':
				return 'register-seller-page';
			default:
				return 'default-page';
		}
	};

	const pageClass = getPageClass(location.pathname);

	return <div className={`page-wrapper ${pageClass}`}>{children}</div>;
};

export default Layout;
