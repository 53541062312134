import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
	Form,
	Button,
	Row,
	Col,
	Popover,
	OverlayTrigger,
	CloseButton,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { register, updateUserProfile } from '../actions/userActions';
import FormContainer from '../components/FormContainer';
import { currencies } from '../components/Currencies';
import axios from 'axios';

const RegisterScreenSeller = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [name, setName] = useState('');
	const [buisnessRegistrationCode, setBuisnessRegistrationCode] = useState('');
	const [bank_account, setBankAccount] = useState('');
	const [phone, setPhone] = useState('');
	const [address, setAddress] = useState('');
	const [message, setMessage] = useState(null);
	const [currency, setCurrency] = useState('INR');
	const [identifyProof, setIdentifyProof] = useState('');
	const [identifyProofNumber, setIdentifyProofNumber] = useState('');
	const [uploading, setUploading] = useState(false);
	const [tempFiles, setTempFiles] = useState([]);
	const [verificationDocs, setVerificationDocs] = useState([]);
	const [showPopover, setShowPopover] = useState(false);

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const userRegister = useSelector((state) => state.userRegister);
	const { loading, error, userInfo } = userRegister;
	const redirect = location.search ? location.search.split('=')[1] : '/';
	const type = new URLSearchParams(location.search).get('type');
	const active = false;

	const uploadFileHandler = async (e) => {
		const files = e.target.files;
		const formData = new FormData();
		formData.append('identifyProof', identifyProof);
		[...files].forEach((file) => {
			formData.append('verificationDocs', file);
		});
		setVerificationDocs([...verificationDocs, ...files]);
		setTempFiles(formData);
	};

	const uploadFiles = useCallback(
		async (userId) => {
			setUploading(true);
			try {
				const config = {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};

				// Create a new FormData object
				const formData = new FormData();
				formData.append('userId', userId); // Append productId first

				// Append existing entries from tempFiles
				tempFiles.forEach((value, key) => {
					formData.append(key, value);
				});

				const { data } = await axios.post(
					'/api/upload/verification-docs',
					formData,
					config
				);
				// Ensure verificationDocs is an array
				//const formattedVerificationDocs = Array.isArray(data) ? data : [data];
				//console.log('Formatted Verification Docs:', formattedVerificationDocs);
				setUploading(false);
				console.log('Data:', data);
				return data;
			} catch (error) {
				console.error(error);
				setUploading(false);
				return [];
			}
		},
		[tempFiles]
	);

	useEffect(() => {
		if (userInfo) {
			const handleUserCreation = async () => {
				const userId = userInfo._id;
				// Upload files with the product ID
				const uploadedImages = await uploadFiles(userId);

				//console.log(uploadedImages);
				// Update the product with the image URLs
				dispatch(
					updateUserProfile({
						_id: userId,
						verificationDocs: uploadedImages,
					})
				);
				// Send email notification
				await sendVerificationEmail(userInfo.email);

				navigate('/profile-review-screen');
			};

			handleUserCreation();
		}
	}, [navigate, userInfo, redirect, uploadFiles, dispatch]);

	const sendVerificationEmail = async (email) => {
		try {
			await axios.post('/api/users/seller-verification-email', { email });
		} catch (error) {
			console.error('Error sending email:', error);
		}
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (
			!name ||
			!phone ||
			!email ||
			!currency ||
			!bank_account ||
			!address ||
			!password ||
			!confirmPassword ||
			(type !== 'individual' && !buisnessRegistrationCode) ||
			!identifyProof ||
			!identifyProofNumber
		) {
			setMessage('Please fill out all fields');
		} else if (password !== confirmPassword) {
			setMessage('Passwords do not match');
		} else {
			let role = 'Buyer'; // Default role
			if (bank_account && buisnessRegistrationCode) {
				role = 'Seller-Company';
			} else if (bank_account) {
				role = 'Seller-Individual';
			}
			dispatch(
				register(
					active,
					name,
					phone,
					email,
					currency,
					role,
					buisnessRegistrationCode,
					bank_account,
					address,
					password,
					identifyProof,
					identifyProofNumber,
					verificationDocs
				)
			);
		}
	};

	const renderPopover = (props) => (
		<Popover id='popover-basic' {...props}>
			<Popover.Header as='h3' className='d-flex justify-content-between'>
				<span>Information</span>
				<CloseButton onClick={() => setShowPopover(false)} />
			</Popover.Header>
			<Popover.Body>
				Sellers who are not registered under GST must have an Enrollment ID or
				UIN for the registration process. If you haven't received your
				Enrollment ID or UIN, you can apply for it{' '}
				<a
					href='https://reg.gst.gov.in/registration/generateuid'
					target='_blank'
					rel='noopener noreferrer'
				>
					here
				</a>{' '}
				and then continue with your registration on SowTrendy.
			</Popover.Body>
		</Popover>
	);

	const getIdentifyProofPattern = () => {
		switch (identifyProof) {
			case 'Passport':
				return '[A-Za-z0-9]{8}';
			case 'Aadhar Card':
				return '[0-9]{12}';
			case 'Pan Card':
				return '[A-Za-z0-9]{10}';
			case 'Driving License':
				return '[A-Za-z0-9]{15,20}';
			default:
				return '';
		}
	};

	return (
		<FormContainer>
			<h1>Sign Up</h1>
			{message && <Message variant='danger'>{message}</Message>}
			{error && <Message variant='danger'>{error}</Message>}
			{loading && <Loader />}
			<Form onSubmit={submitHandler}>
				<Row>
					<Col md={6}>
						<Form.Group controlId='name' className='mb-3'>
							<Form.Label>
								Name <span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								type='name'
								placeholder='Enter Name'
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
							></Form.Control>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId='phone' className='mb-3'>
							<Form.Label>
								Mobile Number <span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								type='tel'
								placeholder='Enter Mobile No.'
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								pattern='[0-9]{10}'
								required
							></Form.Control>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Form.Group controlId='email' className='mb-3'>
							<Form.Label>
								Email Address <span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								type='email'
								placeholder='Enter Email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								autoComplete='email'
								pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'
								required
							></Form.Control>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId='currency' className='mb-3'>
							<Form.Label>
								Choose Currency <span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								as='select'
								value={currency}
								onChange={(e) => setCurrency(e.target.value)}
								required
							>
								{Object.keys(currencies).map((item) => {
									return (
										<option
											value={currencies[item].code}
											key={currencies[item].code}
										>
											{currencies[item].code} - {currencies[item].symbol}
										</option>
									);
								})}
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Form.Group controlId='bank_account' className='mb-3'>
							<Form.Label>
								Bank Account Number <span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter Bank Account Number'
								value={bank_account}
								maxLength='18'
								onChange={(e) => setBankAccount(e.target.value)}
								required
							></Form.Control>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId='address' className='mb-3'>
							<Form.Label>
								{type !== 'individual' ? 'Company Address' : 'Address'}{' '}
								<span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								type='text'
								placeholder={
									type !== 'individual'
										? 'Enter Company Address'
										: 'Enter Address'
								}
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								required
							></Form.Control>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Form.Group controlId='identifyProof' className='mb-3'>
							<Form.Label>
								Identify Proof <span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								as='select'
								value={identifyProof}
								onChange={(e) => setIdentifyProof(e.target.value)}
								required
							>
								<option value=''>Select Identify Proof</option>
								<option value='Passport'>Passport</option>
								<option value='Aadhar Card'>Aadhar Card</option>
								<option value='Driving License'>Driving License</option>
								<option value='Pan Card'>Pan Card</option>
							</Form.Control>
						</Form.Group>
					</Col>
					{identifyProof && (
						<Col md={6}>
							<Form.Group controlId='identifyProofNumber' className='mb-3'>
								<Form.Label>
									{identifyProof} Number <span style={{ color: 'red' }}>*</span>
								</Form.Label>
								<Form.Control
									type='text'
									placeholder={`Enter ${identifyProof} Number`}
									value={identifyProofNumber}
									onChange={(e) => setIdentifyProofNumber(e.target.value)}
									pattern={getIdentifyProofPattern()}
									required
								></Form.Control>
							</Form.Group>
						</Col>
					)}
				</Row>
				{identifyProof && (
					<Row>
						<Col md={6}>
							<Form.Group controlId='identityDocument' className='mb-3'>
								<Form.Label>
									Upload {identifyProof} Document{' '}
									<span style={{ color: 'red' }}>*</span>
								</Form.Label>
								<Form.Control
									type='file'
									onChange={uploadFileHandler}
									required
									multiple
								></Form.Control>
								{uploading && <Loader />}
							</Form.Group>
						</Col>
					</Row>
				)}
				<Row>
					<Col md={6}>
						<Form.Group controlId='buisnessregistrationcode' className='mb-3'>
							<Form.Label>
								{type === 'company' ? 'GSTIN' : 'UIN'} (15-Digits Code){' '}
								{type !== 'company' && (
									<OverlayTrigger
										placement='right'
										trigger='click'
										show={showPopover}
										onHide={() => setShowPopover(false)}
										overlay={renderPopover}
									>
										<i
											className='fas fa-info-circle'
											onClick={() => setShowPopover(!showPopover)}
										></i>
									</OverlayTrigger>
								)}
								<span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								type='text'
								placeholder={type === 'company' ? 'GSTIN' : 'UIN'}
								value={buisnessRegistrationCode}
								maxLength='15'
								onChange={(e) => setBuisnessRegistrationCode(e.target.value)}
								required
							></Form.Control>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Form.Group controlId='password' className='mb-3'>
							<Form.Label>
								Password <span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								type='password'
								placeholder='Enter Password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								autoComplete='new-password'
								required
							></Form.Control>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId='confirmPassword' className='mb-3'>
							<Form.Label>
								Re-type Password <span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								type='password'
								placeholder='Confirm Password'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								autoComplete='new-password'
								required
							></Form.Control>
						</Form.Group>
					</Col>
				</Row>
				<Button type='submit' variant='primary'>
					Register
				</Button>
			</Form>
			<Row className='py-3'>
				<Col>
					<Form.Text muted>Have an Account? </Form.Text>

					<Link to='/login'>Login</Link>
				</Col>
			</Row>
		</FormContainer>
	);
};

export default RegisterScreenSeller;
