// Product.js
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Rating from './Rating';
import { NumericFormat } from 'react-number-format';
import { currencies } from './Currencies';
import { calculateDiscountedPrice } from '../Helpers/calculateDiscountedPrice';

const Product = ({ product, discounts }) => {
	const [discountedPrice, setDiscountedPrice] = useState(product.price);

	useEffect(() => {
		const fetchDiscountedPrice = async () => {
			const price = await calculateDiscountedPrice(
				product._id,
				product.price,
				discounts
			);
			setDiscountedPrice(price);
		};

		fetchDiscountedPrice();
	}, [product, discounts]);

	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;

	// Safely derive user_currency with a fallback to 'USD'
	const user_currency = useMemo(() => {
		const currencyKeys = conversionRates ? Object.keys(conversionRates) : [];
		return currencyKeys.length > 1 ? currencyKeys[1] : 'USD';
	}, [conversionRates]);

	const getConvertedPrice = (price, currency) => {
		if (
			user_currency &&
			currency &&
			currency.toLowerCase() !== user_currency.toLowerCase() &&
			conversionRates?.[user_currency]?.[currency.toLowerCase()]
		) {
			return (
				price / conversionRates[user_currency][currency.toLowerCase()]
			).toFixed(2);
		}
		return price.toFixed(2);
	};

	// Safely retrieve the currency symbol with a fallback to '$'
	const currencySymbol = useMemo(() => {
		return currencies[user_currency.toUpperCase()]?.symbol || '$';
	}, [user_currency]);

	return (
		<Card className='mb-3 rounded product product-card'>
			<Link to={`/product/${product._id}`}>
				<Card.Img
					src={product.image[0].replace('original', 'thumbnail')}
					variant='top'
				/>
			</Link>

			<Card.Body>
				<Link to={`/product/${product._id}`}>
					<Card.Title as='h6' className='title'>
						{product.name}
					</Card.Title>
				</Link>

				<Card.Text as='div'>
					<Rating
						value={product.rating}
						text={`${product.numReviews} reviews`}
					/>
				</Card.Text>

				<Card.Text as='div'>
					<b className='fs-5'>
						{product.countInStock <= 0 ? (
							<span className='text-danger'>Out of Stock</span>
						) : (
							<div className='price-container'>
								{product.price !== discountedPrice &&
									discountedPrice < product.price && (
										<>
											<NumericFormat
												value={getConvertedPrice(
													product.price,
													product.currency
												)}
												displayType={'text'}
												thousandSeparator={true}
												prefix={currencySymbol}
												className='text-muted text-decoration-line-through'
											/>
											&nbsp; {/* Adding non-breaking space here */}
										</>
									)}
								<NumericFormat
									value={getConvertedPrice(discountedPrice, product.currency)}
									displayType={'text'}
									thousandSeparator={true}
									prefix={currencySymbol}
									className={
										product.price !== discountedPrice ? 'text-danger' : ''
									}
								/>
							</div>
						)}
					</b>
				</Card.Text>
				<Card.Text as='div' className='added-by'>
					<small className='text-muted'>
						By: {product.user ? product.user.name : 'Unknown'}
					</small>
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default Product;
