import React, { useState, useEffect, useMemo } from 'react';
import {
	Row,
	Col,
	ListGroup,
	Card,
	Button,
	Image,
	Form,
	Breadcrumb,
} from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import Rating from '../components/Rating';
import { useDispatch, useSelector } from 'react-redux';
import {
	listProductDetails,
	createProductReview,
} from '../actions/productActions';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { NumericFormat } from 'react-number-format';
import { currencies } from '../components/Currencies';
import TruncateMarkup from 'react-truncate-markup';
import SocialShare from '../components/SocialShare';
import { getAllCategoriesByCategoryId } from '../actions/categoryActions';
import { calculateDiscountedPrice } from '../Helpers/calculateDiscountedPrice'; // Import the function

const ProductScreen = () => {
	const [qty, setQty] = useState(1);
	const [rating, setRating] = useState(0);
	const [comment, setComment] = useState('');
	const [photoIndex, setPhotoIndex] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const [shouldTruncate, setShouldTruncate] = useState(true);
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate(); // Use useNavigate

	const productDetails = useSelector((state) => state.productDetails);
	const { loading, error, product } = productDetails;

	const productReviewCreate = useSelector((state) => state.productReviewCreate);
	const { success: successProductReview, error: errorProductReview } =
		productReviewCreate;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;
	const user_currency = conversionRates ? Object.keys(conversionRates)[1] : '';

	// Access discounts from the global state
	const discounts = useSelector(
		(state) => state.productDiscountsList.discounts
	);

	const discountedPrice = useMemo(() => {
		if (product && product.price) {
			return calculateDiscountedPrice(product._id, product.price, discounts);
		}
		return null;
	}, [product, discounts]);

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	};
	const link = {
		color: 'blue',
		textDecoration: 'underline',
		cursor: 'pointer',
	};

	const toggleTruncate = () => {
		shouldTruncate ? setShouldTruncate(false) : setShouldTruncate(true);
	};
	const readMoreEllipsis = (
		<span>
			...{' '}
			<span onClick={toggleTruncate} style={link}>
				read more
			</span>
		</span>
	);

	useEffect(() => {
		if (successProductReview) {
			setRating(0);
			setComment('');
			dispatch(listProductDetails(id));
		}
		if (!product._id || product._id !== id) {
			dispatch(listProductDetails(id));
			dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
		}
	}, [dispatch, id, successProductReview, product._id, qty]);

	const addToCartHandler = () => {
		if (product.countInStock > 0) {
			// Only navigate if in stock
			navigate(`/cart/${id}?qty=${qty}`);
		}
	};

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(
			createProductReview(id, {
				rating,
				comment,
			})
		);
	};

	if (product) {
		// Get all the images of product and convert it into array.
		var images = product.image + '';
		images = images.split(',');
	}

	const handleCloseModal = () => {
		setIsOpen(false);
	};

	// Use React Query to fetch categories
	const {
		data: categories,
		isLoading: categoriesLoading,
		error: categoriesError,
	} = useQuery(
		['categories', product.categoryId],
		() => getAllCategoriesByCategoryId(product.categoryId),
		{
			enabled: !!product.categoryId,
		}
	);

	return (
		<>
			<Breadcrumb>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
					Home
				</Breadcrumb.Item>
				{categoriesLoading && <Loader />}
				{categoriesError && (
					<Message variant='danger'>{categoriesError.message}</Message>
				)}
				{categories && categories.length > 0 && (
					<>
						{categories[0].category && (
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{ to: `/categories/${categories[0].category}` }}
							>
								{categories[0].category}
							</Breadcrumb.Item>
						)}
						{categories[0].subCategory && (
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{
									to: `/categories/${categories[0].category}/${categories[0].subCategory}`,
								}}
							>
								{categories[0].subCategory}
							</Breadcrumb.Item>
						)}
						{categories[0].levelThreeCategory && (
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{
									to: `/categories/${categories[0].category}/${categories[0].subCategory}/${categories[0].levelThreeCategory}`,
								}}
							>
								{categories[0].levelThreeCategory}
							</Breadcrumb.Item>
						)}
						{categories[0].levelFourCategory && (
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{
									to: `/categories/${categories[0].category}/${categories[0].subCategory}/${categories[0].levelThreeCategory}/${categories[0].levelFourCategory}`,
								}}
							>
								{categories[0].levelFourCategory}
							</Breadcrumb.Item>
						)}
						{categories[0].levelFiveCategory && (
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{
									to: `/categories/${categories[0].category}/${categories[0].subCategory}/${categories[0].levelThreeCategory}/${categories[0].levelFourCategory}/${categories[0].levelFiveCategory}`,
								}}
							>
								{categories[0].levelFiveCategory}
							</Breadcrumb.Item>
						)}
					</>
				)}
				<Breadcrumb.Item active>{product.name}</Breadcrumb.Item>
			</Breadcrumb>
			<Link className='btn btn-light my-3' to='/'>
				Go Back
			</Link>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'> {error} </Message>
			) : (
				<>
					<Row>
						<Col md={6}>
							{images.length > 0 ? (
								<>
									<Carousel
										swipeable={true}
										draggable={false}
										showDots={true}
										responsive={responsive}
										ssr={true} // means to render carousel on server-side.
										infinite={true}
										autoPlaySpeed={1000}
										keyBoardControl={true}
										customTransition='all .5'
										transitionDuration={500}
										containerClass='carousel-container'
										removeArrowOnDeviceType={['tablet', 'mobile']}
										dotListClass='custom-dot-list-style'
										itemClass='carousel-item-padding-100-px'
									>
										{images.map((image, index) => (
											<div
												key={index}
												onClick={() => {
													setIsOpen(true);
													setPhotoIndex(index);
												}}
											>
												<Image
													src={image.replace('original', 'medium')}
													alt={product.name}
												></Image>
											</div>
										))}
									</Carousel>
									{isOpen && (
										<Lightbox
											mainSrc={images[photoIndex]}
											nextSrc={images[(photoIndex + 1) % images.length]}
											prevSrc={
												images[(photoIndex + images.length - 1) % images.length]
											}
											onCloseRequest={handleCloseModal}
											onMovePrevRequest={() =>
												setPhotoIndex(
													(photoIndex + images.length - 1) % images.length
												)
											}
											onMoveNextRequest={() =>
												setPhotoIndex((photoIndex + 1) % images.length)
											}
										/>
									)}
								</>
							) : (
								<Image src={product.image} alt={product.name} fluid></Image>
							)}
							<SocialShare product={product} />
						</Col>
						<Col md={3}>
							<ListGroup variant='flush'>
								<ListGroup.Item>
									<h3>{product.name}</h3>
									<Form.Label>Rating</Form.Label>
									<Link to='/'>{}</Link>
								</ListGroup.Item>
								<ListGroup.Item>
									<Rating
										value={product.rating}
										text={`${product.numReviews} reviews`}
									/>
								</ListGroup.Item>
								{shouldTruncate ? (
									<TruncateMarkup lines={8} ellipsis={readMoreEllipsis}>
										<ListGroup.Item>
											Description: {product.description}
										</ListGroup.Item>
									</TruncateMarkup>
								) : (
									<div>
										Description: {product.description}
										<span onClick={toggleTruncate} style={link}>
											{' show less'}
										</span>
									</div>
								)}
							</ListGroup>
						</Col>
						<Col md={3}>
							<Card>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<Row>
											<Col>Price:</Col>
											<Col>
												{user_currency &&
												product.currency &&
												product.currency.toLowerCase() !== user_currency ? (
													<>
														{product.price !== discountedPrice &&
															discountedPrice < product.price && (
																<>
																	<NumericFormat
																		value={(
																			product.price /
																			conversionRates[`${user_currency}`][
																				`${product.currency}`.toLowerCase()
																			]
																		).toFixed(2)}
																		displayType={'text'}
																		thousandSeparator={true}
																		prefix={
																			currencies[
																				`${user_currency}`.toUpperCase()
																			].symbol
																		}
																		className='text-muted text-decoration-line-through'
																	/>
																	&nbsp; {/* Adding non-breaking space here */}
																	<NumericFormat
																		value={(
																			discountedPrice /
																			conversionRates[`${user_currency}`][
																				`${product.currency}`.toLowerCase()
																			]
																		).toFixed(2)}
																		displayType={'text'}
																		thousandSeparator={true}
																		prefix={
																			currencies[
																				`${user_currency}`.toUpperCase()
																			].symbol
																		}
																		className='text-danger'
																	/>
																</>
															)}
														{product.price === discountedPrice && (
															<NumericFormat
																value={(
																	product.price /
																	conversionRates[`${user_currency}`][
																		`${product.currency}`.toLowerCase()
																	]
																).toFixed(2)}
																displayType={'text'}
																thousandSeparator={true}
																prefix={
																	currencies[`${user_currency}`.toUpperCase()]
																		.symbol
																}
															/>
														)}
													</>
												) : (
													<>
														{discountedPrice !== null &&
															product.price !== discountedPrice &&
															discountedPrice < product.price && (
																<>
																	<NumericFormat
																		value={product.price.toFixed(2)}
																		displayType={'text'}
																		thousandSeparator={true}
																		prefix={
																			user_currency &&
																			currencies[
																				`${user_currency}`.toUpperCase()
																			].symbol
																		}
																		className='text-muted text-decoration-line-through'
																	/>
																	&nbsp; {/* Adding non-breaking space here */}
																	<NumericFormat
																		value={discountedPrice.toFixed(2)}
																		displayType={'text'}
																		thousandSeparator={true}
																		prefix={
																			user_currency &&
																			currencies[
																				`${user_currency}`.toUpperCase()
																			].symbol
																		}
																		className='text-danger'
																	/>
																</>
															)}
														{discountedPrice !== null &&
															product.price === discountedPrice && (
																<NumericFormat
																	value={product.price.toFixed(2)}
																	displayType={'text'}
																	thousandSeparator={true}
																	prefix={
																		user_currency &&
																		currencies[`${user_currency}`.toUpperCase()]
																			.symbol
																	}
																/>
															)}
													</>
												)}
											</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item>
										<Row>
											<Col>Status:</Col>
											<Col>
												<strong>
													{product.countInStock > 0
														? 'In Stock'
														: 'Out Of Stock'}
												</strong>
											</Col>
										</Row>
									</ListGroup.Item>
									{product.countInStock > 0 && (
										<ListGroup.Item>
											<Row>
												<Col>Qty</Col>
												<Col>
													<Form.Control
														as='select'
														value={qty}
														onChange={(e) => setQty(e.target.value)}
													>
														{[...Array(product.countInStock).keys()].map(
															(x) => (
																<option key={x + 1} value={x + 1}>
																	{x + 1}
																</option>
															)
														)}
													</Form.Control>
												</Col>
											</Row>
										</ListGroup.Item>
									)}
									<ListGroup.Item>
										<Button
											onClick={addToCartHandler}
											className='btn-block'
											type='button'
											disabled={Number(product.countInStock) <= 0}
											title={
												Number(product.countInStock) <= 0
													? 'Out of Stock'
													: 'Add to Cart'
											}
										>
											Add to Cart
										</Button>
									</ListGroup.Item>
								</ListGroup>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<h2>Reviews</h2>
							{product.reviews.length === 0 && <Message>No Reviews</Message>}
							<ListGroup variant='flush'>
								{product.reviews.map((review) => (
									<ListGroup.Item key={review._id}>
										<strong>{review.name}</strong>
										<Rating value={review.rating} />
										<p>{review.createdAt.substring(0, 10)}</p>
										<p>{review.comment}</p>
									</ListGroup.Item>
								))}
								<ListGroup.Item>
									{errorProductReview && (
										<Message variant='danger'>{errorProductReview}</Message>
									)}
									<h2>Write a Customer Review</h2>
									{userInfo ? (
										<Form onSubmit={submitHandler}>
											<Form.Group controlId='rating'>
												<Form.Label>Rating</Form.Label>
												<Form.Control
													as='select'
													value={rating}
													onChange={(e) => setRating(e.target.value)}
												>
													<option value=''>Select...</option>
													<option value='1'>1 - Poor</option>
													<option value='2'>2 - Fair</option>
													<option value='3'>3 - Good</option>
													<option value='4'>4 - Very Good</option>
													<option value='5'>5 - Excellent</option>
												</Form.Control>
											</Form.Group>
											<Form.Group controlId='comment'>
												<Form.Label>Comment</Form.Label>
												<Form.Control
													as='textarea'
													row='3'
													value={comment}
													onChange={(e) => setComment(e.target.value)}
												></Form.Control>
											</Form.Group>
											<Button type='submit' variant='primary'>
												Submit
											</Button>
										</Form>
									) : (
										<Message>
											Please <Link to='/login'>sign in</Link> to write a review
										</Message>
									)}
								</ListGroup.Item>
							</ListGroup>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default ProductScreen;
