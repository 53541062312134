import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useParams, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import Paginate from '../components/Paginate';
import { listSellerProducts } from '../actions/productActions';
import { fetchAllCategories } from '../Helpers/showCategories';

const ProductListScreenSeller = () => {
	const { pageNumber = 1 } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [categories, setCategories] = useState({});

	const productListSeller = useSelector((state) => state.productListSeller);
	const { loading, error, products, page, pages } = productListSeller;

	const productDelete = useSelector((state) => state.productDelete);
	const {
		loading: loadingDelete,
		error: errorDelete,
		success: successDelete,
	} = productDelete;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	useEffect(() => {
		if (
			!userInfo &&
			!userInfo.role === 'Admin' &&
			!(userInfo.buisnessRegistrationCode || userInfo.bank_account)
		) {
			navigate('/login');
		} else {
			dispatch(listSellerProducts(pageNumber));
		}
	}, [dispatch, userInfo, navigate, successDelete, pageNumber]);

	useEffect(() => {
		const fetchCategoriesForProducts = async () => {
			if (products.length > 0) {
				const categoryMap = await fetchAllCategories(products);
				setCategories(categoryMap);
			}
		};

		fetchCategoriesForProducts();
	}, [products]);

	const toggleActivationHandler = async (id, currentStatus) => {
		if (
			window.confirm(
				`Are you sure you want to ${
					currentStatus ? 'deactivate' : 'activate'
				} this product?`
			)
		) {
			try {
				await axios.put(`/api/products/${id}/toggle`);
				// Refresh the product list or update the state to reflect the change
				dispatch(listSellerProducts());
			} catch (error) {
				console.error('There was an error toggling the product status:', error);
			}
		}
	};

	const createProductHandler = () => {
		navigate('/seller/product/create');
	};
	return (
		<>
			<Row className='align-items-center'>
				<Col>
					<h1>My Products</h1>
				</Col>
				<Col className='text-right'>
					<Button className='my-3' onClick={createProductHandler}>
						{' '}
						<i className='fas fa-plus'></i>Create Product
					</Button>
				</Col>
			</Row>
			{loadingDelete && <Loader />}
			{errorDelete && <Message variant='danger'>{errorDelete}</Message>}
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'> {error}</Message>
			) : (
				<>
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>ID</th>
								<th>NAME</th>
								<th>PRICE</th>
								<th>CATEGORY</th>
								<th>BRAND</th>
								<th>COUNT IN STOCK</th>
								<th>STATUS</th>
								<th>ACTIONS</th>
							</tr>
						</thead>
						<tbody>
							{products.map((product) => (
								<tr key={product._id}>
									<td>{product._id}</td>
									<td>
										<LinkContainer to={`/product/${product._id}`}>
											<Nav.Link>{product.name}</Nav.Link>
										</LinkContainer>
									</td>
									<td>
										{new Intl.NumberFormat('en-US', {
											style: 'currency',
											currency: product.currency,
										}).format(product.price)}
									</td>
									<td>{categories[product.categoryId]}</td>
									<td>{product.brand}</td>
									<td>{product.countInStock}</td>
									<td>{product.status ? 'Active' : 'Inactive'}</td>
									<td>
										<LinkContainer to={`/admin/product/${product._id}/edit`}>
											<Button variant='light' className='btn-sm'>
												<i className='fas fa-edit'></i>
											</Button>
										</LinkContainer>
										<Button
											variant={product.status ? 'danger' : 'success'}
											className='btn-sm'
											onClick={() =>
												toggleActivationHandler(product._id, product.status)
											}
										>
											<i
												className={
													product.status ? 'fas fa-times' : 'fas fa-check'
												}
											></i>
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<Paginate pages={pages} page={page} isSeller={true} />
				</>
			)}
		</>
	);
};

export default ProductListScreenSeller;
