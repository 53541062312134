import axios from 'axios';

export const fetchCategories = async (categoryId) => {
	try {
		const { data } = await axios.get(
			`/api/category/getcategories/${categoryId}`
		);
		const formattedCategories = data.map((category) => {
			let categoryString = category.category;
			if (category.subCategory) categoryString += ` > ${category.subCategory}`;
			if (category.levelThreeCategory)
				categoryString += ` > ${category.levelThreeCategory}`;
			if (category.levelFourCategory)
				categoryString += ` > ${category.levelFourCategory}`;
			if (category.levelFiveCategory)
				categoryString += ` > ${category.levelFiveCategory}`;
			return categoryString;
		});
		return formattedCategories.join(' > ');
	} catch (error) {
		console.error('Error fetching categories:', error);
		return '';
	}
};

export const fetchAllCategories = async (products) => {
	const categoryPromises = products.map(async (product) => {
		const categoryString = await fetchCategories(product.categoryId);
		return { [product.categoryId]: categoryString };
	});

	const categoryResults = await Promise.all(categoryPromises);
	return categoryResults.reduce((acc, curr) => ({ ...acc, ...curr }), {});
};
