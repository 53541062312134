import {
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGOUT,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_DETAILS_FAIL,
	USER_UPDATE_PROFILE_REQUEST,
	USER_UPDATE_PROFILE_SUCCESS,
	USER_UPDATE_PROFILE_FAIL,
	USER_DETAILS_RESET,
	USER_LIST_SUCCESS,
	USER_LIST_REQUEST,
	USER_LIST_FAIL,
	USER_LIST_RESET,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DELETE_FAIL,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_REQUEST,
	USER_UPDATE_FAIL,
	USER_CURRENCY_SUCCESS,
	USER_CURRENCY_FAIL,
	USER_CURRENCY_REQUEST,
} from '../constants/userConstants';
import axios from 'axios';
import { ORDER_LIST_MY_RESET } from '../constants/orderConstants';

export const login = (email, password) => async (dispatch) => {
	try {
		dispatch({
			type: USER_LOGIN_REQUEST,
		});

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.post(
			'/api/users/login',
			{ email, password },
			config
		);

		dispatch({
			type: USER_LOGIN_SUCCESS,
			payload: data,
		});

		localStorage.setItem('userInfo', JSON.stringify(data));
	} catch (error) {
		dispatch({
			type: USER_LOGIN_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const logout = () => (dispatch) => {
	axios
		.post('/api/users/logout')
		.then(() => {
			dispatch({ type: USER_LOGOUT });
			dispatch({ type: USER_DETAILS_RESET });
			dispatch({ type: ORDER_LIST_MY_RESET });
			dispatch({ type: USER_LIST_RESET });
			localStorage.removeItem('userInfo');
		})
		.catch((error) => {
			console.error('Logout failed:', error);
		});
};

export const register =
	(
		active,
		name,
		phone,
		email,
		currency,
		role = 'Buyer',
		buisnessRegistrationCode,
		bank_account,
		address,
		password,
		identifyProof,
		identifyProofNumber,
		VerificationDocs
	) =>
	async (dispatch) => {
		try {
			dispatch({
				type: USER_REGISTER_REQUEST,
			});

			// Fetch the role ID based on the role name
			const { data: roles } = await axios.get('/api/roles');
			const userRole = roles.find((r) => r.name === role);

			if (!userRole) {
				throw new Error(`${role} role not found`);
			}

			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			// Create a common payload
			const payload = {
				name,
				phone,
				email,
				currency,
				role: userRole._id,
				buisnessRegistrationCode,
				bank_account,
				address,
				password,
			};

			// Conditionally add fields based on the role
			if (role !== 'Buyer') {
				payload.active = active;
				payload.identifyProof = identifyProof;
				payload.identifyProofNumber = identifyProofNumber;
				payload.VerificationDocs = VerificationDocs;
			}
			//console.log('payload:', payload);
			// Make the API request
			const { data } = await axios.post('/api/users', payload, config);

			dispatch({
				type: USER_REGISTER_SUCCESS,
				payload: data,
			});

			dispatch({
				type: USER_LOGIN_SUCCESS,
				payload: data,
			});

			localStorage.setItem('userInfo', JSON.stringify(data));
		} catch (error) {
			dispatch({
				type: USER_REGISTER_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			});
		}
	};

export const getUserDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_DETAILS_REQUEST,
		});

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.get(`/api/users/${id}`, config);

		dispatch({
			type: USER_DETAILS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: USER_DETAILS_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_UPDATE_PROFILE_REQUEST,
		});

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(`/api/users/profile`, user, config);

		dispatch({
			type: USER_UPDATE_PROFILE_SUCCESS,
			payload: data,
		});

		dispatch({
			type: USER_LOGIN_SUCCESS,
			payload: data,
		});
		localStorage.setItem('userInfo', JSON.stringify(data));
	} catch (error) {
		dispatch({
			type: USER_UPDATE_PROFILE_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const listUsers = (user) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_LIST_REQUEST,
		});

		const { data } = await axios.get(`/api/users`);

		dispatch({
			type: USER_LIST_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: USER_LIST_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const deleteUser = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_DELETE_REQUEST,
		});

		await axios.delete(`/api/users/${id}`);

		dispatch({
			type: USER_DELETE_SUCCESS,
		});
	} catch (error) {
		dispatch({
			type: USER_DELETE_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const updateUser = (user) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_UPDATE_REQUEST,
		});

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(`/api/users/${user._id}`, user, config);

		dispatch({ type: USER_UPDATE_SUCCESS });

		dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: USER_UPDATE_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const userCurrencyConvert = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_CURRENCY_REQUEST,
		});

		let userPreferredCurrency = 'usd';
		const {
			userLogin: { userInfo },
		} = getState();

		if (!userInfo) {
			try {
				// Get user country and map to its currency and then change the product to user's country currency
				const res = await axios.get('https://ipapi.co/json');
				userPreferredCurrency = (
					res.data.currency ? res.data.currency : 'USD'
				).toLowerCase();
			} catch (error) {
				console.error('Error fetching user currency:', error);
				userPreferredCurrency = 'usd'; // Default to USD if the API call fails
			}
		} else {
			userPreferredCurrency = userInfo.currency.toLowerCase();
		}

		if (userPreferredCurrency) {
			const res = await axios.get(
				`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${userPreferredCurrency}.json`
			);
			dispatch({
				type: USER_CURRENCY_SUCCESS,
				payload: res.data,
			});
		}
	} catch (error) {
		dispatch({
			type: USER_CURRENCY_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
