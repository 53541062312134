import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { LinkContainer } from 'react-router-bootstrap';
import { deleteUser, listUsers } from '../actions/userActions';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { fetchPresignedUrl } from '../Helpers/fetchPresignedUrl';

const UserListScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [roles, setRoles] = useState([]);

	const userList = useSelector((state) => state.userList);
	const { loading, error, users } = userList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userDelete = useSelector((state) => state.userDelete);
	const { success: successDelete } = userDelete;

	useEffect(() => {
		const fetchRoles = async () => {
			const { data } = await axios.get('/api/roles');
			setRoles(data);
		};

		const fetchData = async () => {
			if (
				(userInfo &&
					Array.isArray(userInfo.role) &&
					userInfo.role.includes('Admin')) ||
				userInfo.role.includes('Auditor')
			) {
				dispatch(listUsers());
				await fetchRoles();
			} else {
				navigate('/login');
			}
		};

		fetchData();
	}, [dispatch, userInfo, navigate, successDelete]);

	const getRoleName = (roleId) => {
		const role = roles.find((role) => role._id === roleId);
		return role ? role.name : 'Unknown';
	};

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteUser(id));
		}
	};

	const handleDocClick = async (e, userId, docUrl) => {
		e.preventDefault();
		const key = extractKeyFromUrl(docUrl);
		const url = await fetchPresignedUrl('sowtrendy-dev-uat', key);
		window.open(url, '_blank');
	};

	const extractKeyFromUrl = (url) => {
		const urlObj = new URL(url);
		return decodeURIComponent(urlObj.pathname.substring(1)); // Remove the leading '/' and decode the key
	};

	return (
		<>
			<h1>Users</h1>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<Table striped bordered hover responsive className='table-sm'>
					<thead>
						<tr>
							<th></th>
							<th>NAME</th>
							<th>EMAIL</th>
							<th>ROLE</th>
							<th>CURRENCY</th>
							<th>BUSINESS REGISTRATION NUMBER</th>
							<th>BANK ACCOUNT</th>
							<th>ADDRESS</th>
							<th>IDENTITY PROOF TYPE</th>
							<th>IDENTITY PROOF NUMBER</th>
							<th>VERIFICATION DOCS</th>
							<th>ID</th>
						</tr>
					</thead>
					<tbody>
						{users.map((user) => (
							<tr key={user._id}>
								<td>
									<LinkContainer to={`/admin/user/${user._id}/edit`}>
										<Button variant='light' className='btn-sm'>
											<i className='fas fa-edit'></i>
										</Button>
									</LinkContainer>
									<Button
										variant='danger'
										className='btn-sm'
										onClick={() => deleteHandler(user._id)}
									>
										<i className='fas fa-trash'></i>
									</Button>
								</td>

								<td>{user.name}</td>
								<td>
									<a href={`mailto:${user.email}`}>{user.email}</a>
								</td>
								<td>
									{Array.isArray(user.role)
										? user.role.map((roleId) => getRoleName(roleId)).join(', ')
										: getRoleName(user.role)}
								</td>
								<td>{user.currency}</td>
								<td>{user.buisnessRegistrationCode}</td>
								<td>{user.bank_account}</td>
								<td>{user.address}</td>
								<td>{user.identifyProof}</td>
								<td>{user.identifyProofNumber}</td>
								<td>
									{user.verificationDocs.map((docUrl, index) => (
										<div key={index}>
											<button
												type='button'
												className='btn btn-link p-0'
												onClick={(e) => handleDocClick(e, user._id, docUrl)}
											>
												Document {index + 1}
											</button>
										</div>
									))}
								</td>
								<td>{user._id}</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</>
	);
};

export default UserListScreen;
