import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const ProfileReviewScreen = () => {
	return (
		<Container>
			<Row className='justify-content-md-center'>
				<Col xs={12} md={8}>
					<Card className='mt-5'>
						<Card.Body>
							<Card.Title>Profile Under Review</Card.Title>
							<Card.Text>
								Thank you for applying as seller on SowTrendy! Your profile is
								currently under review. It will take 3-5 days for the review
								process to complete. You will receive an email once your profile
								is verified.
							</Card.Text>
							<Card.Text>
								In case of any problem or any further clarification needed, the
								Sow Trendy team will be in touch with you over the phone or
								email provided.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default ProfileReviewScreen;
